import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Hero from "../components/SharedTailwind/International/Hero";
import Features from "../components/SharedTailwind/International/Features";
import SpeedTest from "../components/SharedTailwind/SpeedTest";
import CTA from "../components/SharedTailwind/International/Cta";
import PH from "../components/SharedTailwind/PH";
import FAQ from "../components/SharedTailwind/FAQ";

function InternationalPage(props) {
  return (
    <React.Fragment>
      <Helmet title="International e-commerce for Shopify">
        <meta
          name="description"
          content="Create an International e-commerce solution for Shopify with Gatsby JS."
        />
      </Helmet>
      <Hero />
      <Features />

      <PH />

      <CTA />

      <SpeedTest />
      <FAQ />
    </React.Fragment>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

export default InternationalPage;
